<template>
  <div style="width: 600px;">
    <v-layout column align="space-between" class="sheet" style="max-width: 550px; margin-top: 10px">
      <!-- Search Results View -->
      <div v-if="state == 'search'">
        <!-- Customer Search -->
        <div>
          <CRInput id="customer-email" v-model="email" label="Email" placeholder="e.g. janedoe@domainexample.com" browser-autocomplete="chrome-off" @input="searchCustomers" />
        </div>

        <!-- Loading -->
        <v-flex v-if="suggestedCustomersLoading">
          <v-layout column justify-space-around style="background-color: white; height: 300px" >
            <v-progress-circular style="margin: 0 auto" :size="70" indeterminate color="primary" />
          </v-layout>
        </v-flex>

        <!-- Suggested Customers List -->
        <v-flex v-if="suggestedCustomers.length > 0" padded xs12 class="mt-2">
          <v-flex v-for="(suggestedCustomer, suggestedCustomerIndex) in suggestedCustomers" :key="`suggestedCustomer-${suggestedCustomerIndex}`">
            <v-layout row wrap class="pa-2 background-blue-light my-3 border-radius-12">
              <v-flex grow column class="customer-suggestion-info pa-2 justify-content-start">
                <h6> {{ suggestedCustomer.firstName }} {{ suggestedCustomer.lastName }} </h6>
                <div>{{ suggestedCustomer.phone | phoneFormatFilter }}</div>
                <div>{{ suggestedCustomer.email }}</div>
                <div v-if="suggestedCustomer.isBanned" :style="`color: ${$cr.theme.error}`" > Banned </div>
                <div v-if="suggestedCustomer.organization"> {{ suggestedCustomer.organization }} </div>
                <div v-if="suggestedCustomer.bookings"> {{ suggestedCustomer.bookings }} bookings per year </div>
              </v-flex>
              <v-flex xs3 column class="align-content-center">
                <v-btn :id="`select-customer-button-${suggestedCustomerIndex}`" v-if="suggestedCustomer.customerAccountId != customerAccountId" class="primary left" @click="selectCustomerFromList(suggestedCustomer)"> Select </v-btn>
                <v-btn :id="`select-customer-button-${suggestedCustomerIndex}`" v-else class="secondary left" disabled> Selected </v-btn>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-flex>
        <!-- Create New Customer -->
        <v-container v-if="!suggestedCustomers.length && !suggestedCustomersLoading && email">
          <v-layout row justify-center>
            <v-flex shrink>
              <CRIcon :width="40" :height="40">my_account</CRIcon>
            </v-flex>
          </v-layout>
          <v-layout row justify-center>
            <v-flex shrink>
              <h3>This looks like a new customer</h3>
              <h3>Create a new customer </h3>
            </v-flex>
            </v-layout>
          <v-layout row justify-center>
            <v-flex shrink>
              <v-btn id="add-new-customer-button" class="btn-primaryaction" @click="addNewCustomer">
                Add New Customer
              </v-btn>
            </v-flex>
          </v-layout>
        </v-container>
      </div>

      <!-- Showing Customer Detail -->
      <v-flex v-if="state == 'detail'" padded xs12 style="margin-left: 30px">
        <v-btn flat class="ml-0 pa-0 text-gray-medium-dark no-background-hover" @click="state = 'search'">
          <div class="v-btn__content justify-start"> < Back </div>
        </v-btn>
        <v-form ref="customerForm" lazy-validation autocomplete="off" onSubmit="return false;" @submit="submitCustomerAccountUserForm">
          <v-layout row wrap>
            <v-flex xs12>
              <CRInput id="user-selector-first-name" v-model="value.firstName" :disabled="existingCustomer" label="First Name" required placeholder="e.g. Jane" browser-autocomplete="chrome-off" autocomplete="off" :rules=" (suggestedCustomers.length === 0 || sameCustomerDifferentEmail) && hasCustomerFieldInputs ? customerFirstNameRules : [] "/>
            </v-flex>
            <v-flex xs12>
              <CRInput id="user-selector-last-name" v-model="value.lastName" :disabled="existingCustomer" label="Last Name" placeholder="e.g. Doe" browser-autocomplete="chrome-off" autocomplete="off" :rules="(suggestedCustomers.length === 0 || sameCustomerDifferentEmail) && hasCustomerFieldInputs ? customerLastNameRules : [] "/>
            </v-flex>
            <v-flex xs12>
              <CRInput id="customer-phone" ref="customer-phone" v-model="value.phone" :disabled="existingCustomer" v-mask="['(###) ###-####', '+## ## #### ####']" label="Phone" placeholder="e.g. (555) 867-5309" browser-autocomplete="chrome-off" :rules=" (suggestedCustomers.length === 0 || sameCustomerDifferentEmail) && hasCustomerFieldInputs ? customerPhoneRules : [] "/>
            </v-flex>
            <v-flex xs12>
              <CRInput id="customer-email" v-model="value.email" required label="Email" :disabled="existingCustomer" placeholder="e.g. janedoe@domainexample.com" browser-autocomplete="chrome-off" :rules="customerEmailRules"/>
            </v-flex>
          </v-layout>
          <div>
            <CRInput id="organization" v-model="value.organization" :disabled="existingCustomer" label="Organization" placeholder="e.g. Spirit Airlines" browser-autocomplete="chrome-off" />
            <CRInput id="bookings-per-year" v-model="value.bookings" :disabled="existingCustomer" label="Bookings per Year" placeholder="e.g. 100" browser-autocomplete="chrome-off"/>
            <IndustrySelector :key="initialIndustryId" ref="industrySelector" v-model="value.industryId" :mode="existingCustomer ? 'view' : 'edit'" :initial-industry="initialIndustryId" :industry-required="industryRequired"/>
            <span v-if="currentCustomerAccount" class="current-customer-account">Current Customer Account: {{ currentCustomerAccount.name }}</span>
            <CustomerAccountSelector disabled v-model="value.customerAccountId"/>
          </div>
          <div class="cr-sidebar-dialog--button-spacer"></div>
          <CRButton class="action-btn" color="primary" @click.stop="submitCustomerAccountUserForm">
            <span>Save</span>
          </CRButton>
        </v-form>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import customerAccounts from '@/services/customerAccounts'

import { phoneFormatFilter } from '@/utils/phone'
import { filter } from '@/utils/filter'
import { mask } from 'vue-the-mask'
import { authComputed } from '@/state/helpers'
import customers from '@/services/customers'

import { deepClone } from '@/utils/deepClone'
import { isNotEmpty, isRequired, validateEmail } from '@/utils/validators'

import CustomerAccountSelector from '@/components/CustomerAccountSelector.vue'
import IndustrySelector from '@/components/IndustrySelector.vue'

import { EventBus } from '@/utils/event-bus'

export default {
  filters: {
    phoneFormatFilter,
  },
  components: {
    IndustrySelector, CustomerAccountSelector,
  },
  directives: { mask },
  inject: ['isInSidebarDialog'],
  props: {
    customerAccountId: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      state: 'search',
      submitting: false,
      suggestedCustomers: [],
      currentCustomerAccount: null,
      suggestedCustomersLoading: false,
      customerEmailTaken: null,
      searchTimeout: null,
      customerFirstNameRules: [
        isRequired(true, isNotEmpty, {
          req: 'First Name Required',
          error: 'First Name Required',
        }),
      ],
      customerLastNameRules: [
        isRequired(true, isNotEmpty, {
          req: 'Last Name Required',
          error: 'Last Name Required',
        }),
      ],
      customerEmailRules: [
        (v) => !!v || 'Email Required',
        (v) => validateEmail(v) || 'Email required and must be valid',
        () => !this.customerEmailTaken || 'Email already exists in the system',
      ],
      customerPhoneRules: [
        isRequired(true, isNotEmpty, {
          req: 'Phone Required',
          error: 'Phone required and must be valid',
        }),
      ],
      industryDescriptionRules: [
        isRequired(true, isNotEmpty, {
          req: 'Industry Description Required',
          error: 'Industry Description Required',
        }),
      ],
    }
  },
  computed: {
    ...authComputed,
    industryDescription() {
      let currentIndustryDescription = this.value.industryDescription

      if (currentIndustryDescription?.length > 45) {
        currentIndustryDescription = currentIndustryDescription.substring(0, 44)
        currentIndustryDescription += '...'
      }

      return currentIndustryDescription
    },
    existingCustomer() {
      return !!this.value.customerId
    },
  },
  methods: {
    addNewCustomer() {
      this.state = 'detail'
      this.currentCustomerAccount = null
      this.value = {
        email: this.email,
        customerAccountId: this.customerAccountId,
      }
      this.suggestedCustomers = []
    },
    async searchCustomersImmediate(email) {
      this.suggestedCustomersLoading = true
      this.suggestedCustomers = []

      let customerFilters = filter()
      let grandParentFilter = customerFilters.createParent('or')
      let parentAndFilter = customerFilters.createParent('and', grandParentFilter)
      email = email.trim()

      if (email && email.length) {
        const emailFilter = {
          column: { _t_id: '63f4ed7a', prop: 'email', filterType: 'contains', },
          value: email,
        }
        customerFilters.add(parentAndFilter, emailFilter)
      }

      const params = {
        filters: customerFilters.asQueryParams(),
        pageSize: 5,
      }
      const matchedCustomers = await customers.getCustomers(params)

      this.suggestedCustomers = matchedCustomers?.data?.resultList || []
      this.suggestedCustomersLoading= false
    },
    async searchCustomers(email) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout)
      }
      if (email.length < 3) {
        return
      }
      this.searchTimeout = setTimeout(() => {
        this.searchCustomersImmediate(email)
      }, 500)
    },
    selectCustomerFromList(customerListItem) {
      if (customerListItem.customerAccountId) {
        customerAccounts.getCustomerAccount(customerListItem.customerAccountId)
          .then((resp) => { this.currentCustomerAccount = resp.data })
      }
      this.value = deepClone(customerListItem)
      this.value.customerAccountId = this.customerAccountId
      this.state = 'detail'
    },
    async submitCustomerAccountUserForm() {
      if (this.state === 'search') {
        return
      }

      if (this.value.customerId) {
        await this.updateExistingCustomer()
      } else {
        await this.createNewCustomer()
      }

      EventBus.$emit('global-dialog-closed-action')
    },
    async updateExistingCustomer() {
      this.submitting = true
      const resp = customers.updateCustomer({
        id: this.value.customerId,
        payload: {
          customerAccountId: this.customerAccountId,
        },
      })

      this.submitting = false
      this.$store.dispatch('app/closeSidebarDialog')

      this.$store.dispatch('app/showAlert', {
        message: 'Customer Updated',
        color: 'success',
      })

    },
    async createNewCustomer() {
      let isValid = this.$refs.customerForm.validate()
      if (!isValid) {
        return
      }

      this.submitting = true

      let {
        email,
        phone: phone,
        firstName,
        lastName,
        bookings,
        organization,
        industryId,
      } = this.value

      if (typeof phone === 'string') {
        phone = phone.replace(/[^0-9]/g, '')
      }

      if (bookings === '--') {
        bookings = null
      }

      const payload = {
        firstName,
        lastName,
        phone,
        email,
        industryId,
        customerAccountId: this.customerAccountId,
        bookings,
        organization,
      }

      try {
        const addCustomerResponse = await customers.addCustomer({ payload })
        // on success, invite customer
        if (addCustomerResponse.status === 200) {
          await customerAccounts.resendCustomerAccountInvite({ email: payload.email })
        }
      } catch (err) {
        console.error(err)
      } finally {
        this.submitting = false
        this.$store.dispatch('app/closeSidebarDialog')
        this.$store.dispatch('app/showAlert', {
          message: 'Customer Added',
          color: 'success',
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.current-customer-account {
  font-size: 14px;
  color: $blue-gray;
  font-style: italic;
}

.customer-suggestions {
  margin-top: 10px;
}

.customer-suggestion-info {
  width: 70%;
}

.action-btn {
  display: flex;
  position: fixed;
  flex-direction: column;
  background: $primary;
  font-size: 18px;
  color: white;
  bottom: 0;
  right: 0;
  width: 600px !important;
  height: 71px !important;
  padding: 24px 0;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
  width: inherit;
  border-radius: 0;
  transition: 0.1s all ease-in-out;

  &:disabled {
    background: $gray-light;
  }
}
.no-background-hover::before {
   background-color: transparent !important;
}
</style>
